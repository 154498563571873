import React, { useEffect, useState } from 'react';
import '../SubLayout.css';


export default function CompanyIntro() {

  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  const [isCollapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit">COMPANY<br></br>INTRODUCTION</h2>
            <p className="sv-txt">제이넷시스템즈는 다양한 경험을 통해 안정적인 네트워크 인프라를 제공하고자 합니다.</p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li className="on">
                  <a href="/CompanyIntro"><span>기업정보</span></a>
                </li>
                <li>
                  <a href="/Recruit"><span>채용정보</span></a>
                </li>
                <li>
                  <a href="/jsoftlab"><span>제이소프트랩</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>회사소개</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li className="on"><a href="/CompanyIntro"><span>기업정보</span></a></li>
            <li><a href="/Recruit"><span>채용정보</span></a></li>
            <li><a href="/jsoftlab" ><span>제이소프트랩</span></a></li>
          </ul>
        </div>
      </div>

      <section className="about">
        <div className="sub-inner">
          <div className="bg">
            <span></span>
            <span></span>
          </div>
          <div className="content" data-aos="fade-up">
            <h3 className="sc-tit">
              다양한 컨설팅 경험과 <br></br>
              구축 노하우를 바탕으로 <br></br>
              고객의 성장을 돕는 <br></br>
              신뢰할 수 있는 기업 <br></br>
            </h3>
            <div>
              <p className="sc-txt">㈜제이넷시스템즈는 고객사 및 통신사에 다양한 IT 비즈니스 솔루션과 네트워크 인프라를 제공하는 컨설팅 파트너로서 지속적인 성장을 해왔습니다.</p>
              <p className="sc-txt">저희 ㈜제이넷시스템즈는 축적된 컨설팅 및 구축 노하우를 바탕으로 사업부문의 다각화 및 기술 역량 강화를 지향하고 있습니다. 이를 통해 공공부문부터 민간기업까지 다양한 고객사에 하드웨어와 소프트웨어의 안정적인 공급 및 통합유지보수를 제공하는 파트너로서 책임을 다하고 있습니다. 토탈 IT 비즈니스 솔루션을 제공하는 전문기업으로써 고객가치 향상과 서비스 만족도를 높일 수 있도록 앞으로도 최선을 다 하겠습니다.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="value">
        <div className="sub-inner">
          <div className="title" data-aos="fade-right">
            <h3 className="sc-tit">(주)제이넷시스템즈가 <br></br>추구하는 핵심가치입니다.</h3>
          </div>

          <div className="content">
            <div data-aos="fade-up">
              <p>“<span className="highlight">사람이 중심이 되어 </span><br></br>끊임없는 도전과 혁신을 통한 <br></br>신뢰받는 기업”</p>
            </div>

            <div className="diagram" data-aos="fade-up">
              <div className="top value-list">
                <ul>
                  <li>
                    <div>
                      <p>CHALLANGE</p>
                      <span>도전</span>
                    </div>
                    <div>
                      <p>고객 부가가치 창출을 위한 끊임없는 도전</p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <p>INNOVATION</p>
                      <span>혁신</span>
                    </div>
                    <div>
                      <p>신기술 도입 및 개발을 통한 지속적인 혁신</p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <p>TRUST</p>
                      <span>신뢰</span>
                    </div>
                    <div>
                      <p>안정적이고 지속적인 서비스 제공</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="top value-core">
                <div>
                  <p>CORE VALUE</p>
                  <span>사람 중심 기업</span>
                  {/* <img src={value}></img> */}
                </div>
              </div>
              <div className="ani-roof">
                <span className="triangle cir_1"></span>
                <span className="triangle cir_2"></span>
                <span className="triangle cir_3"></span>
                <svg viewBox="0 0 300 300" width={300} height={300}>
                  <circle
                    cx="150" cy="150" r="150"
                    strokeDasharray="5,5" strokeWidth={1} strokeLinecap='round' strokeOpacity={0.9} stroke="#1c436790" fill="white"></circle>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="history">
        <div className="bg">
          <span></span>
          <span></span>
        </div>
        <div className="sub-inner">
          <div className="title" data-aos="fade-right">
            <p className="sc-txt">연혁</p>
            <h3 className="sc-tit">(주)제이넷시스템즈가<br></br>걸어온 길입니다.</h3>
          </div>

          <div className="content" data-aos="fade-up">
            <div className={`h-list ${isCollapsed ? 'collapsed' : 'outspread'}`}>
              <div className="item left">
                <div>
                  <strong>2023</strong>
                  <p>그랜드스트림 국내 수입총판 계약</p>
                </div>
              </div>
              <div className="item right">
                <div>
                  <strong>2022</strong>
                  <p>㈜제이소프트랩 설립</p>
                </div>
              </div>
              <div className="item right">
                <div>
                  <strong>2022</strong>
                  <p>프라이빗 테크놀로지 파트너사 계약</p>
                </div>
              </div>
              <div className="item left">
                <div>
                  <strong>2021</strong>
                  <p>KT협력사 등록</p>
                </div>
              </div>
              <div className="item right">
                <div>
                  <strong>2020</strong>
                  <p>자체 개발 NMS “Wi-Sight V1.0” 출시</p>
                </div>
              </div>
              <div className="item left">
                <div>
                  <strong>2019</strong>
                  <p>RUCKUS네트웍스 파트너 계약</p>
                </div>
              </div>
              <div className="item left">
                <div>
                  <strong>2019</strong>
                  <p>코어엣지네트웍스 파트너 계약</p>
                </div>
              </div>
              <div className="item left">
                <div>
                  <strong>2019</strong>
                  <p>CISCO 파트너사 등록</p>
                </div>
              </div>
              <div className="item right">
                <div>
                  <strong>2018</strong>
                  <p>㈜제이넷시스템즈 서울지사 설립</p>
                </div>
              </div>
              <div className="item right">
                <div>
                  <strong>2018</strong>
                  <p>워치가드(UTM) 유통부문 총판계약</p>
                </div>
              </div>
              <div className="item left">
                <div>
                  <strong>2017</strong>
                  <p>삼성전자 WLAN 부문 파트너사 등록</p>
                </div>
              </div>
              <div className="item right">
                <div>
                  <strong>2016</strong>
                  <p>삼성전자 PBX Reseller 파트너사 등록</p>
                </div>
              </div>
              <div className="item left">
                <div>
                  <strong>2015</strong>
                  <p>한국소프트웨어산업협회 사업자 등록</p>
                </div>
              </div>
              <div className="item left">
                <div>
                  <strong>2015</strong>
                  <p>한국정보통신공사협회 사업자 등록</p>
                </div>
              </div>
              <div className="item right">
                <div>
                  <strong>2014</strong>
                  <p>LG유플러스 협력사 등록</p>
                </div>
              </div>
              <div className="item right">
                <div>
                  <strong>2014</strong>
                  <p>㈜제이넷시스템즈 설립</p>
                </div>
              </div>
            </div>

            <div className={`folding-btn ${isCollapsed ? 'show' : 'hide'}`}>
              <button onClick={toggleCollapse}>펼치기<span></span></button>
            </div>
          </div>
        </div>
      </section>

      <section className="org">
        <div className="sub-inner">
          <div className="title" data-aos="fade-up">
            <h3 className="sc-tit">ORG CHART</h3>
          </div>

          <div className="line-group" data-aos="fade-up">
            <div className="step1">
              <svg className="line1">
                <circle cx="265" cy="290" r="6" strokeWidth="2" fill="#144F95"></circle>
                <path className="path1"
                  d="M272 290 L 340 290 C 340 290, 400 290, 400 220 V 50 L 400 50 C 400 50, 400 10, 450 10"
                  strokeWidth="2"
                  fill="none">
                </path>
              </svg>
              <svg className="line2">
                <path className="path4"
                  d="M272 290 L 340 290 C 340 290, 400 290, 400 220 V 180 L 400 200 C 400 200, 400 200, 420 200 H 420 L 450 200"
                  strokeWidth="2"
                  fill="none">
                </path>
              </svg>
              <svg className="line3">
                <path className="path8"
                  d="M272 290 L 340 290 C 340 290, 400 290, 400 360 V 380 L 400 390 H 450"
                  strokeWidth="2"
                  fill="none">
                </path>
              </svg>
              <svg className="line4">
                <path className="path12"
                  d="M272 290 L 340 290 C 340 290, 400 290, 400 360 V 380 L 400 510 C 400 510, 400 560, 450 560"
                  strokeWidth="2"
                  fill="none">
                </path>
              </svg>
            </div>
            <div className="step2">
              <svg className="line5">
                <circle cx="332" cy="340" r="7" strokeWidth="2" fill="#144F95"></circle>
                <path className="path15"
                  d="M 332 340 L 440 340 C 440 340, 520 340, 520 260 V 80 L 520 80 C 520 80, 520 20, 580 20 L 620 20"
                  strokeWidth="2"
                  fill="none">
                </path>
              </svg>
              <svg className="line6">
                <path className="path18"
                  d="M 332 340 L 440 340 C 440 340, 520 340, 520 260 V 260 C 520 260, 520 240, 560 240 L 620 240"
                  strokeWidth="2"
                  fill="none">
                </path>
              </svg>
              <svg className="line7">
                <path className="path22"
                  d="M 332 340 L 440 340 C 440 340, 520 340, 520 440 V 440 C 520 440, 520 460, 580 460 L 620 460"
                  strokeWidth="2"
                  fill="none"
                >
                </path>
              </svg>
              <svg className="line8">
                <path className="path24"
                  d="M 332 340 L 440 340 C 440 340, 520 340, 520 440 V 600 L 520 600 C 520 600, 520 660, 580 660 L 620 660"
                  strokeWidth="2"
                  fill="none"
                >
                </path>
              </svg>
            </div>
          </div>

          <div className="content-area" data-aos="fade-up">
            <div className="org-chart">
              <div className="ceo">
                <p>CEO</p>
              </div>
              <ul className="org-list">
                <li>
                  <div>
                    <span>01</span>
                    <p>영업본부</p>
                    <i>Business Headquarters</i>
                  </div>
                  <ul>
                    <li>
                      <p>솔루션 영업팀</p>
                      <i>Solution Business</i>
                    </li>
                    <li>
                      <p>네트워크 보안 영업팀</p>
                      <i>Network Security</i>
                    </li>
                  </ul>
                </li>
                <li>
                  <div>
                    <span>02</span>
                    <p>기술본부</p>
                    <i>Technical Headquarters</i>
                  </div>
                  <ul>
                    <li>
                      <p>기술 1팀</p>
                      <i>UC & IPT</i>
                    </li>
                    <li>
                      <p>기술 2팀</p>
                      <i>Wireless</i>
                    </li>
                    <li>
                      <p>기술 3팀</p>
                      <i>Wired & Security</i>
                    </li>
                  </ul>
                </li>
                <li>
                  <div>
                    <span>03</span>
                    <p>경영지원</p>
                    <i>Management Support</i>
                  </div>
                  <ul>
                    <li>
                      <p>경영지원팀</p>
                      <i>Management Support</i>
                    </li>
                  </ul>
                </li>
                <li>
                  <div>
                    <span>04</span>
                    <p>제이소프트랩</p>
                    <i>Jsoftlab</i>
                  </div>
                  <ul>
                    <li>
                      <p>개발팀</p>
                      <i>Development</i>
                    </li>
                    <li>
                      <p>디자인팀</p>
                      <i>Design</i>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </section>
    </div>
  )
}