import React, { useEffect, useRef, useState } from 'react';
import '../SubLayout.css';
import Map from '../../pages/support/images/map.svg';

const { kakao } = window;
const suwonData = [
  {
    title: "서울 지사",
    lat: 37.5617178,
    lng: 127.0339790,
  },
]

const seoulData = [
  {
    title: "수원 본사",
    lat: 37.2733333,
    lng: 127.0568746,
  }
]

export default function Directions() {
  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  //서브 탭 메뉴
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }


  //카카오 지도
  useEffect(() => {
    suwonMap();
    seoulMap();
  }, [])
  const suwonMap = () => {
    let suwon = document.getElementById("map");
    let suwonOption = {
      center: new kakao.maps.LatLng(37.5617178, 127.0339790),
      level: 3,
    };

    //map
    const map = new kakao.maps.Map(suwon, suwonOption);

    suwonData.forEach((el) => {
      // 마커를 생성합니다
      new kakao.maps.Marker({
        //마커가 표시 될 지도
        map: map,
        //마커가 표시 될 위치
        position: new kakao.maps.LatLng(el.lat, el.lng),
        //마커에 hover시 나타날 title
        title: el.title,
      });
    });

    // Add map type control
    const mapTypeControl = new kakao.maps.MapTypeControl();
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

    // Add zoom control
    const zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
  };


  const seoulMap = () => {
    let seoul = document.getElementById("smap");
    let seoulOption = {
      center: new kakao.maps.LatLng(37.2733333, 127.0568746),
      level: 3,
    };
    const map = new kakao.maps.Map(seoul, seoulOption);

    seoulData.forEach((el) => {
      new kakao.maps.Marker({
        map: map,
        position: new kakao.maps.LatLng(el.lat, el.lng),
        title: el.title,
      });
    });

    // Add map type control
    const mapTypeControl = new kakao.maps.MapTypeControl();
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

    // Add zoom control
    const zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
  };


  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit"><br></br>오시는길</h2>
            <p className="sv-txt"> </p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li>
                  <a href="/QuoteInquiry"><span>견적문의</span></a>
                </li>
                <li className="on">
                  <a href="/Directions"><span>오시는길</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>고객문의</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a href="/QuoteInquiry"><span>견적문의</span></a>
            </li>
            <li className="on">
              <a href="/Directions"><span>오시는길</span></a>
            </li>
          </ul>
        </div>
      </div>

      <section className="address">
        <div className="sub-inner">
          <div className="title" data-aos="fade-up">
            <h3>회사 위치</h3>
          </div>
          <div className="wrap" data-aos="fade-up">
            <div id='smap' className="map">
              <div className="kkomap_btn">
                <a href="https://kko.to/WTfR-VNrh_" target='_blank'><img src={Map} alt="제이넷시스템즈 수원 본사 길찾기"></img></a>
              </div>
            </div>

            <div className="address-info">
              <p>본사</p>
              <ul>
                <li>
                  <label>주소</label><p>수원시 영통구 원천동 627번지 현대테라타워영통 215호<br></br><span>13, Sinwon-ro 250beon-gil, Yeongtong-gu, Suwon-si, Gyeonggi-do, Republic of Korea</span></p>
                </li>
                <li>
                  <label>전화</label><p>02. 2294. 0002</p>
                </li>
                <li>
                  <label>팩스</label><p>02. 2292. 0002</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="wrap" data-aos="fade-up">
            <div id="map" className="map">
              <div className="kkomap_btn">
                <a href="https://kko.to/bKRv9PuIN7" target='_blank'><img src={Map} alt="제이넷시스템즈 서울 지사 길찾기"></img></a>
              </div>
            </div>

            <div className="address-info">
              <p>지사</p>
              <ul>
                <li>
                  <label>주소</label><p>서울시 성동구 무학봉 28길 19 강문빌딩 2층, 3층<br></br><span>19, Muhakbong 28-gil, Seongdong-gu, Seoul, Republic of Korea</span></p>
                </li>
                <li>
                  <label>전화</label><p>02. 2294. 0002</p>
                </li>
                <li>
                  <label>팩스</label><p>02. 2292. 0002</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
