import React, { useRef, useState, useEffect, lazy, Suspense } from 'react';
import '../SubLayout.css';
import '../../css/style.css';

const CallServer = lazy(() => import('../../components/product/ipt/CallServer'));
const MediaGate = lazy(() => import('../../components/product/ipt/MediaGate'));
const IpPhone = lazy(() => import('../../components/product/ipt/IpPhone'));
const EtcSolution = lazy(() => import('../../components/product/ipt/EtcSolution'));

export default function Ipt() {

  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  const [activeComponent, setActiveComponent] = useState(CallServer);
  const [activeButton, setActiveButton] = useState('콜서버');

  const loadComponent = (componentName) => {
    if (componentName === '콜서버') {
      setActiveComponent(CallServer);
    } else if (componentName === '미디어게이트웨이') {
      setActiveComponent(MediaGate);
    } else if (componentName === 'IP Phone') {
      setActiveComponent(IpPhone);
    } else if (componentName === '부가솔루션') {
      setActiveComponent(EtcSolution);
    }
    setActiveButton(componentName);
  };


  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit"><br></br>IPT</h2>
            <p className="sv-txt">효율적인 솔루션을 위한 제품을 제공합니다.</p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li className="on">
                  <a href="/Ipt"><span>IPT</span></a>
                </li>
                <li>
                  <a href="/Network"><span>NETWORK</span></a>
                </li>
                <li>
                  <a href="/Wlan"><span>WLAN</span></a>
                </li>
                <li>
                  <a href="/Security"><span>SECURITY</span></a>
                </li>
                {/* <li>
                  <a href="/Software"><span>R&D</span></a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>제품정보</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li className="on">
              <a href="/Ipt"><span>IPT</span></a>
            </li>
            <li>
              <a href="/Network"><span>NETWORK</span></a>
            </li>
            <li>
              <a href="/Wlan"><span>WLAN</span></a>
            </li>
            <li>
              <a href="/Security"><span>SECURITY</span></a>
            </li>
            {/* <li>
              <a href="/Software"><span>R&D</span></a>
            </li> */}
          </ul>
        </div>
      </div>

      <div className="sub-tab">
        <div className="sub-inner">
          <button className={activeButton === '콜서버' ? 'on' : ''} onClick={() => loadComponent('콜서버')}>Call Server</button>
          <button className={activeButton === '미디어게이트웨이' ? 'on' : ''} onClick={() => loadComponent('미디어게이트웨이')}>Media Gateway</button>
          <button className={activeButton === 'IP Phone' ? 'on' : ''} onClick={() => loadComponent('IP Phone')}>IP Phone</button>
          <button className={activeButton === '부가솔루션' ? 'on' : ''} onClick={() => loadComponent('부가솔루션')}>3rd Party</button>
        </div>
      </div>

      <div className="contents">
        <Suspense 
          fallback={
            <div className="loader">
              <div className="loader-box">
                <div></div>
              </div>
            </div>
          }
        >
          {activeComponent && React.createElement(activeComponent)}
        </Suspense>
      </div>
    </div>
  )
}
