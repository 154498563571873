import React, { useEffect, useRef, useState } from 'react';
import '../SubLayout.css';


export default function NetworkBuilding() {
  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  const [activeCol, setActiveCol] = useState(0);

  const handleClick = (index) => {
    setActiveCol(index);
  };

  const handleMouseOver = (index) => {
    setActiveCol(index);
  };

  const handleMouseOut = () => {
    // 마우스가 나간 후에 activeCol을 null로 설정하지 않음
  };


  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit">NETWORK<br></br>CONSULTING</h2>
            <p className="sv-txt">안정적인 기술과 우수한 신뢰성으로 고객만족을 위한 솔루션을 혁신하고 제공합니다.</p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li>
                  <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
                </li>
                <li className="on">
                  <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
                </li>
                <li>
                  <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
                </li>
                <li>
                  <a href="/Maintenance"><span>통합 유지보수</span></a>
                </li>
                <li>
                  <a href="/Distributor"><span>총판사업</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>사업분야</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
            </li>
            <li className="on">
              <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
            </li>
            <li>
              <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
            </li>
            <li>
              <a href="/Maintenance"><span>통합 유지보수</span></a>
            </li>
            <li>
              <a href="/Distributor"><span>총판사업</span></a>
            </li>
          </ul>
        </div>
      </div>

      <section className="governing">
        <div className="sub-inner">
          <div className="title">
            <h3 className="tit" data-aos="fade-up">
              NETWORK 구축 컨설팅
            </h3>
            <p className="txt" data-aos="fade-up">제이넷시스템즈는 고객중심의 성공적인 네트워킹 구축을 위한<br></br>체계적이고 효율적인 통합 네트워크 컨설팅을 제공합니다.</p>
          </div>
        </div>
      </section>

      <section className="business-info">
        <div className="sub-inner">
          <ul className="info-list">
            <li>
              <div className="bg" data-aos="fade-right"><span></span></div>
              <div className="con" data-aos="fade-up">
                <p className="num">01</p>
                <p className="tit">네트워크 컨설팅</p>
                <p className="txt">
                  유무선 인프라 통합 구축 /<br></br>
                  고객 맞춤형 컨설팅 및 제안, 설계 구축
                </p>
                <ul>
                  <li>- 백본, L2, L3, L4, L7 스위치</li>
                  <li>- 차세대 QoS 스위치</li>
                  <li>- 네트워크 통신 공사</li>
                </ul>
              </div>
            </li>

            <li>
              <div className="bg" data-aos="fade-left"><span></span></div>
              <div className="con" data-aos="fade-up">
                <p className="num">02</p>
                <p className="tit">보안 컨설팅</p>
                <p className="txt">
                  네트워크 경계 보안 구축 /<br></br>
                  네트워크 구간 보안 구축 / 단말 보안 구축
                </p>
                <ul>
                  <li>- 방화벽,UTM,VPN</li>
                  <li>- 차세대 VPN, 접근제어</li>
                  <li>- NAC, EDR</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="process">
        <div className="sub-inner">
          <div className="title">
            <h3 className="mc-tit" data-aos="fade-up" data-aos-duration="800">프로세스</h3>
          </div>
          <div className="contents-box" data-aos="fade-up" data-aos-duration="800">
            <div className="proc-slide">
              <div className="proc-list">
                <div className="grid">
                  <div
                    className={`col ${activeCol === 0 ? 'active' : ''}`} 
                    onClick={() => handleClick(0)}
                    onMouseOver={() => handleMouseOver(0)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>01</span>
                          <p>네트워크 컨설팅</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>고객사 네트워크 환경 및 요구사항에 맞는 최적의 네트워크 인프라 제시</p></li>
                            <li><p>네트워크 정밀진단</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 1 ? 'active' : ''}`} 
                    onClick={() => handleClick(1)}
                    onMouseOver={() => handleMouseOver(1)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>02</span>
                          <p>네트워크 구축전략</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>네트워크 구축을 위한 서버실 내부 환경 파악 및 구성도 현황 작성</p></li>
                            <li><p>구축 설계 및 일정 협의</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 2 ? 'active' : ''}`} 
                    onClick={() => handleClick(2)}
                    onMouseOver={() => handleMouseOver(2)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                        <span>03</span>
                          <p>네트워크 구축</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>네트워크 설계에 따른 하드웨어 장비 설치</p></li>
                            <li><p>네트워크 망구성</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 3 ? 'active' : ''}`} 
                    onClick={() => handleClick(3)}
                    onMouseOver={() => handleMouseOver(3)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>04</span>
                          <p>네트워크 운용</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>네트워크 시스템 운용 관리 및 장애관리</p></li>
                            <li><p>용량/성능/백업/복구 관리</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 4 ? 'active' : ''}`} 
                    onClick={() => handleClick(4)}
                    onMouseOver={() => handleMouseOver(4)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>05</span>
                          <p>네트워크 보안관제</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>방화벽 및 보안서버연동 설치 및 보안 정책 적용, 안정성 확보</p></li>
                            <li><p>보안 분석 및 대응 제시</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagi">
                <span className={`bullet ${activeCol === 0 ? 'active' : ''}`} onClick={() => handleClick(0)} />
                <span className={`bullet ${activeCol === 1 ? 'active' : ''}`} onClick={() => handleClick(1)} />
                <span className={`bullet ${activeCol === 2 ? 'active' : ''}`} onClick={() => handleClick(2)} />
                <span className={`bullet ${activeCol === 3 ? 'active' : ''}`} onClick={() => handleClick(3)} />
                <span className={`bullet ${activeCol === 4 ? 'active' : ''}`} onClick={() => handleClick(4)} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inquiry_banner">
        <div className="sub-inner">
          <h3 className="sc-tit" data-aos="fade-up">궁금한 사항을 간편하게 문의해보세요</h3>
          <p className="sc-txt" data-aos="fade-up">제이넷시스템즈의 전문가가 궁금한 점을 해결해 드립니다</p>
          <a href="/QuoteInquiry" data-aos="fade-up">문의하기</a>
        </div>
      </section>
    </div>
  )
}