import React, { useEffect, useState } from 'react';
import '../SubLayout.css';

import svc1 from '../business/images/main_svc1.svg';
import svc2 from '../business/images/main_svc2.svg';
import svc3 from '../business/images/main_svc3.svg';

export default function Maintenance() {
  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit"><br></br>MAINTENANCE</h2>
            <p className="sv-txt">안정적인 기술과 우수한 신뢰성으로 고객만족을 위한 솔루션을 혁신하고 제공합니다.</p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li>
                  <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
                </li>
                <li>
                  <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
                </li>
                <li>
                  <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
                </li>
                <li className="on">
                  <a href="/Maintenance"><span>통합 유지보수</span></a>
                </li>
                <li>
                  <a href="/Distributor"><span>총판사업</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>사업분야</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
            </li>
            <li>
              <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
            </li>
            <li>
              <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
            </li>
            <li className="on">
              <a href="/Maintenance"><span>통합 유지보수</span></a>
            </li>
            <li>
              <a href="/Distributor"><span>총판사업</span></a>
            </li>
          </ul>
        </div>
      </div>

      <section className="governing">
        <div className="sub-inner">
          <div className="title" data-aos="fade-up">
            <p>유무선네트워크/IPT/보안 인프라의 통합 <span className="highlight">유지보수를 All-in-One 지원</span>합니다</p>
          </div>
        </div>
        <div className="gif" data-aos="fade-up">
          <img src="../images/business/maintenance.gif"></img>
        </div>
      </section>

      <section className="w-diagram">
        <div className="sub-inner">
          <div className="function" data-aos="fade-up">
            <div className="cir_m">관공서, 금융, 통신, 제조 등 다양한 환경의 시스템을 고객 요구에 맞는 유지관리지원 서비스로 제안합니다</div>

            <div className="cir_b">
              <ul className="sol">
                <li>IPT System</li>
                <li>Security</li>
                <li>Wire Network System</li>
                <li>Wireless Network System</li>
              </ul>
              <div>
                <p>Total<br></br>Solution</p>
                <span>축적된 유지보수 경험을 통한 체계화된 유지보수 수행</span>
              </div>
            </div>

            <div className="cir_m">ONE Call 서비스 제공</div>
          </div>

          <div className="keyword" data-aos="fade-up">
            <div>
              <ul>
                <li>시스템 신뢰성 • <br></br>안정성 확보</li>
                <li>시스템 운영<br></br>효율성 제고</li>
                <li>장애처리 및<br></br>리스크 관리</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="service">
        <div className="sub-inner">
          <div className="title" data-aos="fade-right">
            <h3 className="sc-tit">주요 서비스</h3>
          </div>
          <div className="content" data-aos="fade-up">
            <ul className="svc-list">
              <li className="item">
                <div className="icon"><img src={svc1}></img></div>
                <div>
                  <p>365*24시간 상시 운영</p>
                  <ul>
                    <li>- 신속하고 명확한 업무연속성 확보를 통해 안정적 시스템 운영</li>
                    <li>- 사용자 및 운영자 만족도 향상</li>
                  </ul>
                </div>
              </li>
              <li className="item">
                <div className="icon"><img src={svc2}></img></div>
                <div>
                  <p>시스템 유지관리 체계 정립</p>
                  <ul>
                    <li>- 유지보수 업무 프로세스를 통한 관리</li>
                    <li>- 유지보수 일원화를 통한 통합관리 지원</li>
                    <li>- 정기적인 예방 정비를 통한 장애 요인 제거</li>
                    <li>- 신속한 장애 복구</li>
                  </ul>
                </div>
              </li>
              <li className="item">
                <div className="icon"><img src={svc3}></img></div>
                <div>
                  <p>시스템 기술교육 체계 정립</p>
                  <ul>
                    <li>- 시스템 설치 및 운영에 필요한 사전 교육</li>
                    <li>- 구축 시스템 사용 및 운영</li>
                    <li>- 시스템 운영자 및 관리자 교육 과정</li>
                    <li>- IT 신기술 트렌드 공유</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="inquiry_banner">
        <div className="sub-inner">
          <h3 className="sc-tit" data-aos="fade-up">궁금한 사항을 간편하게 문의해보세요</h3>
          <p className="sc-txt" data-aos="fade-up">제이넷시스템즈의 전문가가 궁금한 점을 해결해 드립니다</p>
          <a href="/QuoteInquiry" data-aos="fade-up">문의하기</a>
        </div>
      </section>
    </div>
  )
}
