import React, { useEffect, useState } from 'react';
import '../SubLayout.css';

export default function Distributor() {

  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit"><br></br>DISTRIBUTOR</h2>
            <p className="sv-txt">안정적인 기술과 우수한 신뢰성으로 고객만족을 위한 솔루션을 혁신하고 제공합니다.</p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li>
                  <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
                </li>
                <li>
                  <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
                </li>
                <li>
                  <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
                </li>
                <li>
                  <a href="/Maintenance"><span>통합 유지보수</span></a>
                </li>
                <li className="on">
                  <a href="/Distributor"><span>총판사업</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>사업분야</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
            </li>
            <li>
              <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
            </li>
            <li>
              <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
            </li>
            <li>
              <a href="/Maintenance"><span>통합 유지보수</span></a>
            </li>
            <li className="on">
              <a href="/Distributor"><span>총판사업</span></a>
            </li>
          </ul>
        </div>
      </div>

      <section className="partner">
        <div className="sub-inner">
          <ul className="list">
            <li className="item">
              <div className="sumnail" data-aos="fade-up"></div>
              <div className="info" data-aos="fade-up">
                <div>
                  <img className="logo" src="../images/product/grandstream_logo.svg"></img>
                  <div className="btn-bl-bk">
                    <a href="https://www.grandstream.com/" target="_blank" rel="noopener noreferrer">그랜드스트림 바로가기</a>
                  </div>
                </div>
                <p>
                  <b>유무선 네트워크 솔루션의 글로벌 선두기업, GRANDSTREAM Networks Inc.</b>
                  GRANDSTREAM Networks Inc.는 2002년부터 통합 커뮤니케이션 및 네트워킹 솔루션 제품을 개발 및 생산하여 전 세계를 연결하고 있는 글로벌 기업 입니다. GRANDSTREAM Networks Inc.는 미국 매사추세츠 주 보스턴에 본사를 두고 있으며, 개발된 솔루션은 150개 이상의 국가의 여러 글로벌 기업들로부터 품질이 검증되어 제품에 대한 신뢰도가 높은 IT전문기업 입니다. GRANDSTREAM Networks Inc. 의 제품들은 가격대비 성능이 훌륭하고, 전용 플랫폼을 통해 클라우드 네트워킹부터 IT통합관리시스템에 이르기까지 원스톱으로 솔루션을 제공하는 점이 특징입니다. 또한 GRANDSTREAM Networks Inc.는 직접 설립한 유통 채널을 통해 다양한 제품들을 전 세계에 공급하고 있습니다. 본사가 있는 미국 매사추세츠주(보스턴) 외에도 미국 캘리포니아주(로스앤젤레스), 미국 텍사스주(댈러스), 중국, 베네수엘라, 모로코, 말레이시아 및 스페인에 지사를 두고 있습니다. GRANDSTREAM Networks Inc.는 누구보다 혁신적인 제품을 시장에 우선적으로 내 놓고자 연구 및 개발에 아낌없이 투자하고 있습니다. 이를 위해 고객들의 다양한 상황과 수요에 대해 경청하며, 조직 내 혁신을 돕고 개선할 수 있는 최적의 솔루션을 제공하고 있습니다.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  )
}
