import React, { useEffect, useState } from 'react';

export default function ScrollTop() {

    // 상단 이동 버튼
    const [showButton, setShowButton] = useState(false);
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })
  
    }
    useEffect(() => {
        const handleShowButton = () => {
            if (window.scrollY > 500) {
                setShowButton(true)
            } else {
                setShowButton(false)
            }
        }
        window.addEventListener("scroll", handleShowButton)
        return () => {
            window.removeEventListener("scroll", handleShowButton)
        }
    }, [])

  return (
    <div>
      { showButton && (
          <div className="scroll-container">
            <button id='top' onClick={scrollToTop} type='button'><img src="../images/main/arrow_top_bk.svg"></img>TOP</button>
          </div>
        )
      }
    </div>
  )
}
