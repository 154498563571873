import React, { useEffect, useRef, useState } from 'react';
import '../SubLayout.css';


import cable from '../product/images/cable.svg';
import corded from '../product/images/corded_phone.svg';
import diary from '../product/images/diary.svg';
import pc from '../product/images/pc.svg';
import wlan from '../product/images/wlan.svg';
import mobile from '../product/images/mobile.svg';
import notePc from '../product/images/note_pc.svg';
import app from '../product/images/application.svg';
import svc1 from '../business/images/wlan_svc1.svg';
import svc2 from '../business/images/wlan_svc2.svg';
import svc3 from '../business/images/wlan_svc3.svg';
import svc4 from '../business/images/wlan_svc4.svg';

export default function WlanBuilding() {

  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  const [activeCol, setActiveCol] = useState(0);

  const handleClick = (index) => {
    setActiveCol(index);
  };

  const handleMouseOver = (index) => {
    setActiveCol(index);
  };

  const handleMouseOut = () => {
    // 마우스가 나간 후에 activeCol을 null로 설정하지 않음
  };

  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit">WLAN<br></br>CONSULTING</h2>
            <p className="sv-txt">안정적인 기술과 우수한 신뢰성으로 고객만족을 위한 솔루션을 혁신하고 제공합니다.</p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li>
                  <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
                </li>
                <li>
                  <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
                </li>
                <li className="on">
                  <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
                </li>
                <li>
                  <a href="/Maintenance"><span>통합 유지보수</span></a>
                </li>
                <li>
                  <a href="/Distributor"><span>총판사업</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>사업분야</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
            </li>
            <li>
              <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
            </li>
            <li className="on">
              <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
            </li>
            <li>
              <a href="/Maintenance"><span>통합 유지보수</span></a>
            </li>
            <li>
              <a href="/Distributor"><span>총판사업</span></a>
            </li>
          </ul>
        </div>
      </div>
      <section className="governing">
        <div className="sub-inner">
          <div className="title" data-aos="fade-up">
            <p className="txt">끊임없이 언제 어디서나</p>
            <h3 className="tit">Wireless LAN</h3>
          </div>

          <div className="contnet trend">
            <div className="title" data-aos="fade-up">
              <p className="sc-tit">Wireless<br></br><span>WiFi6</span></p>
              <p className="sc-txt">기존의 Wi-Fi 기술이 발전하여 더 빠르고 안정적인 인터넷 연결이 가능해졌습니다. 사무실 내에서 노트북, 스마트폰 등 다양한 디바이스를 이용한 작업이 효율적으로 이루어질 수 있도록 사무실에서의 작업환경을 개선하고 생산성을 높이는 데 기여하고 있습니다. 또한 Wi-Fi 6와 Wi-Fi 6E 같은 새로운 표준이 도입되어 대역폭 확장과 혼잡 완화가 이루어지면서 더욱 뛰어난 성능을 제공하고 있습니다.</p>
            </div>

            <div className="area">
              <div data-aos="fade-right">
                <span className="bg"></span>
                <div data-aos="fade-up">
                  <p><b>유선 기업 환경</b></p>
                  <p>Wired Enterprise Environment</p>
                  <ul>
                    <li>
                      <span><img src={cable}></img></span>
                      <p>Cable</p>
                    </li>
                    <li>
                      <span><img src={corded}></img></span>
                      <p>Corded<br></br>Phone</p>
                    </li>
                    <li>
                      <span><img src={diary}></img></span>
                      <p>Diary</p>
                    </li>
                    <li>
                      <span><img src={pc}></img></span>
                      <p>PC</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div data-aos="fade-left">
                <span className="bg"></span>
                <div data-aos="fade-up">
                  <p><b>무선 기업 환경</b></p>
                  <p>Wireless Enterprise Environment</p>
                  <ul>
                    <li>
                      <span><img src={wlan}></img></span>
                      <p>WLAN</p>
                    </li>
                    <li>
                      <span><img src={mobile}></img></span>
                      <p>Mobile<br></br>Device</p>
                    </li>
                    <li>
                      <span><img src={notePc}></img></span>
                      <p>Note-PC</p>
                    </li>
                    <li>
                      <span><img src={app}></img></span>
                      <p>Business<br></br>Application</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="business-info">
        <div className="sub-inner">
          <ul className="info-list">
            <li>
              <div className="bg" data-aos="fade-right"><span></span></div>
              <div className="con" data-aos="fade-up">
                <p className="num">01</p>
                <p className="tit">고성능의 무선 네트워크 설계</p>
                <p className="txt">
                  최신 성능에 무선 AP 설계/<br></br>
                  무선 AP 성능에 100% 부합하는 네트워크 망 설계
                </p>
                <ul>
                  <li>- IEEE 802.11a/b/g/n/ac/ax/be</li>
                  <li>- 고성능 네트워크 스위치</li>
                  <li>- 최적의 회선 대역폭 제안</li>
                  <li>- 네트워크 케이블 규격</li>
                </ul>
              </div>
            </li>

            <li>
              <div className="bg" data-aos="fade-left"><span></span></div>
              <div className="con" data-aos="fade-up">
                <p className="num">02</p>
                <p className="tit">올인원 설계/구축 지원</p>
                <p className="txt">
                  무선 네트워크에 필요한 모든 장비 설계/<br></br>
                  설계부터 구축, 유지관리까지 올인원 지원
                </p>
                <ul>
                  <li>- 무선랜, 보안, 네트워크, 부가솔루션 등</li>
                  <li>- 유지관리에 용이한 자사 NMS 솔루션 보유</li>
                  <li>- 전담 엔지니어를 배정하여 구축/유지관리 지원</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="process">
        <div className="sub-inner">
          <div className="title">
            <h3 className="mc-tit" data-aos="fade-up" data-aos-duration="800">프로세스</h3>
          </div>
          <div className="contents-box" data-aos="fade-up" data-aos-duration="800">
            <div className="proc-slide">
              <div className="proc-list">
                <div className="grid">
                  <div
                    className={`col ${activeCol === 0 ? 'active' : ''}`} 
                    onClick={() => handleClick(0)}
                    onMouseOver={() => handleMouseOver(0)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>01</span>
                          <p>정확한 사전 분석</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>고객사 요구사항에 맞는 최적의 인프라 설계</p></li>
                            <li><p>Simulation 및 현장 실사</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 1 ? 'active' : ''}`} 
                    onClick={() => handleClick(1)}
                    onMouseOver={() => handleMouseOver(1)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>02</span>
                          <p>성능 및 확장성 확보</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>유사사업의 구축 경험을 바탕으로 서비스 기간을 고려한 최적의 솔루션 제안</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 2 ? 'active' : ''}`} 
                    onClick={() => handleClick(2)}
                    onMouseOver={() => handleMouseOver(2)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>03</span>
                          <p>보안 강화</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>고객사 상황에 맞춘 사용자 인증 및 무선망 네트워크 보안 강화</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 3 ? 'active' : ''}`} 
                    onClick={() => handleClick(3)}
                    onMouseOver={() => handleMouseOver(3)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>04</span>
                          <p>통합관리 및 운영</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>통합 솔루션 구축으로 관리 및 운영 효율성 극대화</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagi">
                <span className={`bullet ${activeCol === 0 ? 'active' : ''}`} onClick={() => handleClick(0)} />
                <span className={`bullet ${activeCol === 1 ? 'active' : ''}`} onClick={() => handleClick(1)} />
                <span className={`bullet ${activeCol === 2 ? 'active' : ''}`} onClick={() => handleClick(2)} />
                <span className={`bullet ${activeCol === 3 ? 'active' : ''}`} onClick={() => handleClick(3)} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service">
        <div className="sub-inner">
          <div className="title" data-aos="fade-right">
            <h3 className="sc-tit">주요 서비스</h3>
          </div>
          <div className="content">
            <div className="list-wrap" data-aos="fade-up">
              <div>
                <p className="tit">INDOOR</p>
                <p className="txt">사내 무선 네트워크 / 공공기관 청사 와이파이 / 스마트팩토리 / RFID 기반 시설 / 스쿨넷 무선랜 시스템</p>
              </div>

              <ul className="svc-list">
                <li className="item">
                  <div className="icon"><img src={svc1}></img></div>
                  <div>
                    <p>무선랜</p>
                    <ul>
                      <li>- 루커스네트웍스</li>
                      <li>- HPE 아루바</li>
                      <li>- 그랜드스트림</li>
                    </ul>
                  </div>
                </li>
                <li className="item">
                  <div className="icon"><img src={svc2}></img></div>
                  <div>
                    <p>보안</p>
                    <ul>
                      <li>- UTM</li>
                      <li>- 인증서버</li>
                      <li>- WIPS</li>
                    </ul>
                  </div>
                </li>
                <li className="item">
                  <div className="icon"><img src={svc3}></img></div>
                  <div>
                    <p>부가솔루션</p>
                    <ul>
                      <li>- 유해차단시스템</li>
                      <li>- 통합로그시스템</li>
                      <li>- 웹포탈솔루션</li>
                      <li>- wNMS</li>
                    </ul>
                  </div>
                </li>
                <li className="item">
                  <div className="icon"><img src={svc4}></img></div>
                  <div>
                    <p>ETC</p>
                    <ul>
                      <li>- IoT 솔루션</li>
                      <li>- 위치기반 솔루션</li>
                      <li>- 유동인구 빅데이터분석</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>

            <div className="list-wrap" data-aos="fade-up">
              <div>
                <p className="tit">OUTDOOR</p>
                <p className="txt">공공 와이파이 / 메쉬 네트워크 / 무선 브릿지 / 스마트 항만 / 산불 감지 / 건설현장</p>
              </div>

              <ul className="svc-list">
                <li className="item">
                  <div className="icon"><img src={svc1}></img></div>
                  <div>
                    <p>무선랜</p>
                    <ul>
                      <li>- 루커스네트웍스</li>
                      <li>- HPE 아루바</li>
                      <li>- 그랜드스트림</li>
                    </ul>
                  </div>
                </li>
                <li className="item">
                  <div className="icon"><img src={svc2}></img></div>
                  <div>
                    <p>보안</p>
                    <ul>
                      <li>- UTM</li>
                      <li>- 인증서버</li>
                      <li>- WIPS</li>
                    </ul>
                  </div>
                </li>
                <li className="item">
                  <div className="icon"><img src={svc3}></img></div>
                  <div>
                    <p>부가솔루션</p>
                    <ul>
                      <li>- 유해차단시스템</li>
                      <li>- 통합로그시스템</li>
                      <li>- 웹포탈솔루션</li>
                      <li>- wNMS</li>
                    </ul>
                  </div>
                </li>
                <li className="item">
                  <div className="icon"><img src={svc4}></img></div>
                  <div>
                    <p>ETC</p>
                    <ul>
                      <li>- IoT 솔루션</li>
                      <li>- 위치기반 솔루션</li>
                      <li>- 유동인구 빅데이터분석</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="inquiry_banner">
        <div className="sub-inner">
          <h3 className="sc-tit" data-aos="fade-up">궁금한 사항을 간편하게 문의해보세요</h3>
          <p className="sc-txt" data-aos="fade-up">제이넷시스템즈의 전문가가 궁금한 점을 해결해 드립니다</p>
          <a href="/QuoteInquiry" data-aos="fade-up">문의하기</a>
        </div>
      </section>
    </div>
  )
}
