import React, { useState, useEffect } from 'react';
import './Footer.css';
import { useLocation } from 'react-router-dom';
import  logo from './logo.svg';

export default function Footer() {
  const [OnClick , setOnClick] = useState(false);
  const location = useLocation();

  const handleOnClick = () => { 
    setOnClick(!OnClick);
  } 

  const renderFooterInfo = () => {
    // 현재 경로에 따라 특정 페이지인지 확인
    if (location.pathname === '/jsoftlab') {
      // JSoftLab 페이지일 때 보여줄 텍스트
      return (
        <>
          <p>제이소프트랩</p>
          <ul>
            <li><label>대표이사 :</label><span>임준식</span></li>
            <li><label>대표번호 :</label><span>02. 2294. 0010</span></li>
            <li><label>팩스 :</label><span>02. 2292. 0002</span></li>
            <li><label>이메일 :</label><span>sales@jnetsystems.com</span></li>
            <li><label>주소 :</label><span>서울시 성동구 무학봉 28길 19 강문빌딩 2층, 3층</span></li>
          </ul>
        </>
      );
    } else {
      // 다른 페이지일 때 기본 텍스트 보여주기
      return (
        <>
          <p>주식회사 제이넷시스템즈</p>
          <ul>
            <li><label>대표이사 :</label><span>임준식</span></li>
            <li><label>사업자등록번호 :</label><span>206-86-90079</span></li>
            <li><label>대표번호 :</label><span>02. 2294. 0002</span></li>
            <li><label>팩스 :</label><span>02. 2292. 0002</span></li>
            <li><label>이메일 :</label><span>sales@jnetsystems.com</span></li>
            <li><label>본사 :</label><span>수원시 영통구 원천동 627번지 현대테라타워영통 215호</span></li>
            <li><label>지사 :</label><span>서울시 성동구 무학봉 28길 19 강문빌딩 2층, 3층</span></li>
          </ul>
        </>
      );
    }
  };

  return(
    <footer id="footer">
      <div className="footer-wrap">
        <div className="footer-info">
          {renderFooterInfo()}
        </div>

        <div className="footer-menu">
          <p className="footer-logo">
            <img src={logo} className='logo'></img>
          </p>
          <div className="privacy">
            <button className="open-btn" onClick={()=>handleOnClick()}>개인정보처리방침</button>
            <div className={OnClick ? "popup open" : "popup"}>
              <div className="popup-inner">
                <h3>개인정보처리방침</h3>
                <div className="info">
                  <p>'제이넷시스템즈' 는 (이하 '회사'는) 고객님의 개인정보를 중요시하며, '정보통신망 이용촉진 및 정보보호'에 관한 법률을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</p>
                  <p>회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</p>
                  <p>- 본 방침은 : 2015 년 10 월 01 일 부터 시행됩니다.</p>
                  <div>
                    <ul>
                      <li>
                        <p className="tit">1.수집하는 개인정보 항목</p>
                        <p>회사는 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.</p>
                        <ul>
                          <li>①수집항목 : 이름 , 비밀번호 , 휴대전화번호 , 주소, 이메일 , 서비스 이용기록 , 접속 로그 , 쿠키 , 접속 IP 정보 , 기타내용</li>
                          <li>②개인정보 수집방법 : 제품문의</li>
                        </ul>
                      </li>
                      <li>
                        <p className="tit">2.개인정보의 수집 및 이용목적</p>
                        <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
                        <ul>
                          <li>①콘텐츠 제공</li>
                          <li>②회원 관리 : 개인 식별 , 불만처리 등 민원처리 , 고지사항 전달</li>
                        </ul>
                      </li>
                      <li>
                        <p className="tit">3.개인정보의 보유 및 이용기간</p>
                        <p>회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.</p>
                      </li>
                      <li>
                        <p className="tit">4.개인정보의 파기절차 및 방법</p>
                        <p>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</p>
                        <ul>
                          <li>
                            ①파기절차<br></br>
                            <br></br>
                            : 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
                          </li>
                          <li>
                            ②파기방법<br></br>
                            <br></br>
                            : 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p className="tit">5.개인정보 제공</p>
                        <p>회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
                        <ul>
                          <li>①이용자들이 사전에 동의한 경우</li>
                          <li>②법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                        </ul>
                      </li>
                      <li>
                        <p className="tit">6.수집한 개인정보의 위탁</p>
                        <p>회사는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.</p>
                      </li>
                      <li>
                        <p className="tit">7.개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</p>
                        <p>쿠키 등 인터넷 서비스 이용 시 자동 생성되는 개인정보를 수집하는 장치를 운영하지 않습니다.</p>
                      </li>
                      <li>
                        <p className="tit">8.개인정보에 관한 민원서비스</p>
                        <p>회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.</p>
                        <ul>
                          <li>개인정보관리책임자 성명 : 제이넷시스템즈</li>
                          <li>전화번호 :  02-2294-0002</li>
                          <li>이메일 : jjkim@jnetsystems.com</li>
                        </ul>
                      </li>
                    </ul>
                    <p>귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>
                    <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
                    <ul>
                      <li>1.개인정보침해신고센터<br></br>(www.1336.or.kr/국번없이 118)</li>
                      <li>2.정보보호마크인증위원회<br></br>(www.eprivacy.or.kr/02-580-0533~4)</li>
                      <li>3.대검찰청 인터넷범죄수사센터<br></br>(http://icic.sppo.go.kr/02-3480-3600)</li>
                      <li>4.경찰청 사이버테러대응센터<br></br>(www.ctrc.go.kr/02-392-0330)</li>
                    </ul>
                  </div>
                </div>
                <div className="close-btn" onClick={()=>handleOnClick()}>
                  <button>확인</button>
                </div>  
              </div>
            </div>
          </div>
          <p>Copyright ©2023 (주)제이넷시스템즈. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}