import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import playIcon from '../components/images/play.svg';
import stopIcon from '../components/images/stop.svg';

import { Swiper, SwiperSlide } from '../../node_modules/swiper/swiper-react';
import { FreeMode, Navigation, Pagination, Autoplay } from '../../node_modules/swiper/modules';


// home ScrollY 함수
function useScrollDirection() {

  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset; // function to run on scroll
    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset; // 스크롤이 수직으로 얼마나 스크롤이 됐는지 픽셀 단위로 반환한다.
      console.log(scrollY);
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    }
  }, [scrollDirection])
  return scrollDirection;
}

export default function Home() {
  const swiperRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const swiper = swiperRef.current.swiper;
    document.querySelector('.slide-txt').classList.add('on');

    swiper.on('slideChange', () => {

      const activeIndex = swiper.activeIndex;

      document.querySelectorAll('.slide-txt').forEach((element) => {
        element.classList.remove('on');
      });

      document.querySelectorAll('.slide-txt')[activeIndex].classList.add('on');
    });

    const playStopButton = document.querySelector('.play-control');

    playStopButton.addEventListener('click', () => {
      if (isPlaying) {
        swiper.autoplay.stop();
      } else {
        swiper.autoplay.start();
      }
      setIsPlaying(!isPlaying);
    });
  }, [isPlaying]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    // 슬라이드 내용을 배열에 정의합니다.
    <div key={1} className="slide">
      <a href="/IptConsulting">
        <div>
          <p><strong>IPT 시스템 컨설팅</strong></p>
          <p>IPT System Consulting</p>
        </div>
      </a>
    </div>,
    <div key={2} className="slide">
      <a href="/NetworkBuilding">
        <div>
          <p><strong>NETWORK 구축 컨설팅</strong></p>
          <p>Network Building Consulting</p>
        </div>
      </a>
    </div>,
    <div key={3} className="slide">
      <a href="/WlanBuilding">
        <div>
          <p><strong>WLAN 구축 컨설팅</strong></p>
          <p>WLAN Building Consulting</p>
        </div>
      </a>
    </div>,
    <div key={4} className="slide">
      <a href="/Maintenance">
        <div>
          <p><strong>통합 유지보수</strong></p>
          <p>Maintenance</p>
        </div>
      </a>
    </div>,
    <div key={5} className="slide">
      <a href="/Distributor">
        <div>
          <p><strong>총판사업</strong></p>
          <p>Distributor</p>
        </div>
      </a>
    </div>,
  ];

  const goToSlide = (index) => {
    // 특정 슬라이드로 이동하는 함수
    setCurrentSlide(index);
  };

  const prevSlide = () => {
    // 이전 슬라이드로 이동하는 함수
    const prevSlideIndex = currentSlide - 1 < 0 ? slides.length - 1 : currentSlide - 1;
    setCurrentSlide(prevSlideIndex);
  };

  const nextSlide = () => {
    // 다음 슬라이드로 이동하는 함수
    const nextSlideIndex = currentSlide + 1 >= slides.length ? 0 : currentSlide + 1;
    setCurrentSlide(nextSlideIndex);
  };

  return (
    <div className="content">
      {/* 1.메인 비주얼 */}
      <section className="main-visual">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          rewind={true}
          navigation={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true
          }}
          spaceBetween={0}
          slidesPerView={1}
          className="visual-slide"
          ref={swiperRef}
          breakpoints={{
            1920: {
              slidesPerView: 1,
              autoplay: {
                delay: 8000,
              }
            },
            1023: {
              slidesPerView: 1,
              autoplay: {
                delay: 3000,
              }
            },
          }}
        >
          <SwiperSlide>
            <div className="mv_slide bg1">
              <video src="../images/main/mv_video.mp4" muted autoPlay loop></video>
              <div className="grey-sheet"></div>
            </div>
            <div className="slide-txt">
              <h2 className="mv-tit">
                <span>
                  <span>Successful</span>
                </span>
                <br></br>
                <span>
                  <span>Business</span>
                </span>
              </h2>
              <p className="mv-txt">고객님의 성공적인 비즈니스를 위한 맞춤형 IT솔루션 제공 및 <br></br>끊임없는 만족 향상을 위한 Total Solution 컨설팅을 제공합니다</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="mv_slide bg2">
              <div className="grey-sheet"></div>
            </div>
            <div className="slide-txt">
              <h2 className="mv-tit">
                <span>
                  <span>NMS</span>
                </span>
                <br></br>
                <span>
                  <span>Development</span>
                </span>
              </h2>
              <p className="mv-txt">높은 기술력과 다양한 개발 경험을 바탕으로 <br></br>고객과 소통하며 각종 소프트웨어와 시스템을 개발합니다</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="mv_slide bg3">
              <div className="grey-sheet"></div>
            </div>
            <div className="slide-txt">
              <h2 className="mv-tit">
                <span>
                  <span>Total IT</span>
                </span>
                <br></br>
                <span>
                  <span>Solution</span>
                </span>
              </h2>
              <p className="mv-txt">기업의 IPT, 무선 네트워크 인프라 제품군을 포함한 다양한 맞춤형 IT솔루션으로 <br></br>고객 만족 향상을 위한 Total Solution 컨설팅을 제공합니다</p>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="play-control">
          <div className="play-stop-button" role='button' onClick={() => setIsPlaying(!isPlaying)}>
            <img src={isPlaying ? stopIcon : playIcon} alt={isPlaying ? '멈춤' : '재생'} />
          </div>
        </div>


        <div className="scroll-down">
          <div className="row">
            <span className="label">
              <span>Scroll Down</span>
              <span className="line"></span>
            </span>
          </div>
        </div>
      </section>

      <section className="intro">
        <div className="main-inner">
          <div className="box">
            <div className="title">
              <h3 className="mc-tit" data-aos="fade-up" data-aos-duration="800">Our Company</h3>
              <p className="mc-txt" data-aos="fade-up" data-aos-duration="800">하드웨어와 소프트웨어의 안정적인 공급 및 <br></br>통합유지보수를 제공하는 전문 IT 기업</p>
            </div>
            <div className="box-list" data-aos="fade-up" data-aos-duration="800">
              <div>
                <div className="cover">
                  <p>비전<br></br>&미션</p>
                  <div className="btn-fl-wh">
                    <a href="/CompanyIntro">자세히보기</a>
                  </div>
                </div>
                <div className="bg">
                  <p>Vision & Mission<span></span></p>
                  <img src="../images/main/intro_bg1.jpg" ></img>
                </div>
              </div>
              <div>
                <div className="cover">
                  <p>제이소프트랩</p>
                  <div className="btn-fl-wh">
                    <a href="/jsoftlab">자세히보기</a>
                  </div>
                </div>
                <div className="bg">
                  <p>Jsoftlab<span></span></p>
                  <img src="../images/main/intro_bg2.jpg" ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="bg" data-aos="fade-up" data-aos-duration="800">
              <img src="../images/main/intro_bg3.jpg" ></img>
            </div>
            <div className="title">
              <h3 className="mc-tit" data-aos="fade-up" data-aos-duration="800"><span>컨설팅 & 솔루션</span><br></br>전문 엔지니어링 회사</h3>
              <p className="mc-txt" data-aos="fade-up" data-aos-duration="800">제이넷시스템즈는 차별화된 기술 역량과 컨설팅 경험을 바탕으로 <br></br>고객의 다양한 환경에 맞춰 최적화된 솔루션을 제공합니다.</p>
              <div className="btn-bl-bk" data-aos="fade-up" data-aos-duration="800">
                <a href="/CompanyIntro">회사소개</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="business">
        <div className="main-inner">
          <div className="title">
            <h3 className="mc-tit" data-aos="fade-up" data-aos-duration="800">Our<br></br>Business</h3>
            <p className="mc-txt" data-aos="fade-up" data-aos-duration="800">제이넷시스템즈는 고객 만족 극대화를 최우선으로 생각하며 최고의 서비스와 성능을 제공합니다.</p>
          </div>
          <div className="control" data-aos="fade-up" data-aos-duration="800">
            <button className="prev-button" onClick={prevSlide}><img src="../images/main/arrow_left.svg" alt="이전"></img></button>
            <div className="pagination">
              <div className="slide-count">
                <span>0{currentSlide + 1}</span>
                <div className="progressbar">
                  <div
                    className="progressbar-fill"
                    style={{ width: `${((currentSlide + 1) / slides.length) * 100}%` }}
                  ></div>
                </div>
                <span>0{slides.length}</span>
              </div>
            </div>
            <button className="next-button" onClick={nextSlide}><img src="../images/main/arrow_right.svg" alt="다음"></img></button>
          </div>
          <div className="business-list" data-aos="fade-up" data-aos-duration="800">
            <div className="slider-container">
              <div className="slider">
                <div className="slide-wrapper">
                  {slides.map((slide, index) => (
                    <div
                      key={index}
                      className={`slide-item ${index === currentSlide ? 'active' : ''}`}
                      style={{
                        transform: `translateX(-${currentSlide * 100}%)`,
                        transition: 'all 0.3s ease'
                      }}
                    >
                      {slide}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product">
        <div className="main-inner">
          <div className="title">
            <h3 className="mc-tit" data-aos="fade-up" data-aos-duration="800">제품라인</h3>
          </div>
          <div className="contents-box" data-aos="fade-up" data-aos-duration="800">
            <div className="product-slide">
              <Swiper
                slidesPerView={5}
                freeMode={true}
                modules={[FreeMode]}
                className="product-list"
              >
                <SwiperSlide>
                  <div className="grid">
                    <div className="col">
                      <div className="product-item">
                        <div className="item-inner">
                          <div className="product-bg">
                            <img src="../images/main/product_bg1.jpg"></img>
                          </div>
                          <div className="product-title">
                            <h3><strong>Network</strong></h3>
                            <div className="btn-more">
                              <a href="/Network">자세히보기</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="product-item">
                        <div className="item-inner">
                          <div className="product-bg"><img src="../images/main/product_bg2.jpg"></img></div>
                          <div className="product-title">
                            <h3><strong>IPT</strong></h3>
                            <p></p>
                            <div className="btn-more">
                              <a href="/Ipt">자세히보기</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="product-item">
                        <div className="item-inner">
                          <div className="product-bg"><img src="../images/main/product_bg3.jpg"></img></div>
                          <div className="product-title">
                            <h3><strong>WLAN</strong></h3>
                            <p></p>
                            <div className="btn-more">
                              <a href="/Wlan">자세히보기</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="product-item">
                        <div className="item-inner">
                          <div className="product-bg"><img src="../images/main/product_bg4.jpg"></img></div>
                          <div className="product-title">
                            <h3><strong>보안</strong></h3>
                            <p></p>
                            <div className="btn-more">
                              <a href="/Security">자세히보기</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="product-item">
                        <div className="item-inner">
                          <div className="product-bg"><img src="../images/main/product_bg5.jpg"></img></div>
                          <div className="product-title">
                            <h3><strong>R&D</strong></h3>
                            <p></p>
                            <div className="btn-more">
                              <a href="/Software">자세히보기</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="inquiry" data-aos="fade-up" data-aos-duration="1000">
        <div className="main-inner">
          <div className="inquiry_bg">
            <div className="title">
              <div>
                <h3 className="mc-tit">궁금한 사항을<br></br>간편하게 물어보세요</h3>
                <div className="btn-more">
                  <a href="/QuoteInquiry">견적문의하기</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}