import './css/reset.css';
import './css/style.css';
import './App.css';

import '../node_modules/@fontsource/noto-sans-kr/korean.css';
import '../node_modules/@fontsource/roboto/latin.css'
import '../node_modules/@fontsource/poppins/latin.css';

// import '../node_modules/ionicons/dist/ionicons.esm.js';
import '../node_modules/ionicons/dist/ionicons.js';

import React, { useEffect, useState } from "react";
import AOS from '../node_modules/aos/dist/aos';
import "../node_modules/aos/dist/aos.css";

import Swiper from '../node_modules/swiper/swiper-bundle';
import '../node_modules/swiper/swiper-bundle.css';

import CompanyIntro from './pages/company/CompanyIntro';
import Recruit from './pages/company/Recruit';
import JSoftLab from './pages/company/jsoftlab/index.js';
import IptConsulting from './pages/business/IptConsulting';
import WlanBuilding from './pages/business/WlanBuilding';
import NetworkBuilding from './pages/business/NetworkBuilding';
import Maintenance from './pages/business/Maintenance';
import Distributor from './pages/business/Distributor';
import Network from './pages/product/Network';
import Ipt from './pages/product/Ipt';
import Wlan from './pages/product/Wlan';
import Security from './pages/product/Security';
import SoftWare from './pages/product/SoftWare';
import QuoteInquiry from './pages/support/QuoteInquiry';
import Directions from './pages/support/Directions'
import { Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Footer from './components/Footer';
import SolutionDevelopment from './pages/business/NetworkBuilding';
import ScrollTop from '../src/components/ScrollTop';

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [scrollValue, setScrollValue ] = useState(null);
  let arr = [];
  useEffect(() => {
  let lastScrollY = window.pageYOffset;
  const updateScrollDirection = () => {
    const scrollY = window.pageYOffset;
    const direction = scrollY > lastScrollY ? "down" : "up";
  if(scrollY < 390){
    const direction2 = "hide";
    setScrollValue(direction2);
    } else {
    const direction2 = "show";
    setScrollValue(direction2);
    }
  if (direction !== scrollDirection && (scrollY - lastScrollY > 3 || scrollY - lastScrollY < -3)) {
    setScrollDirection(direction);
  }
  lastScrollY = scrollY > 0 ? scrollY : 0;
  };
  window.addEventListener("scroll", updateScrollDirection); // add event listener
  return () => {
    window.removeEventListener("scroll", updateScrollDirection); // clean up
  }
  }, [scrollDirection]);
  arr['a'] = scrollDirection;
  arr['b'] = scrollValue
  return arr;
};

function App() {
  const scrollDirection = useScrollDirection();
  useEffect(() => {
    AOS.init();
  })

  return (
    <>
      <NavBar scrollDirection={scrollDirection}/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="companyintro" element={<CompanyIntro/>}/>
        <Route path="recruit" element={<Recruit/>}/>
        <Route path="jsoftlab" element={<JSoftLab/>}/>
        <Route path="iptconsulting" element={<IptConsulting/>}/>
        <Route path="wlanbuilding" element={<WlanBuilding/>}/>
        <Route path="networkbuilding" element={<NetworkBuilding/>}/>
        <Route path="maintenance" element={<Maintenance/>}/>
        <Route path="distributor" element={<Distributor/>}/>
        <Route path="solutiondevelopment" element={<SolutionDevelopment/>}></Route>
        <Route path="network" element={<Network/>}/>
        <Route path="ipt" element={<Ipt/>}/>
        <Route path="wlan" element={<Wlan/>}/>
        <Route path="security" element={<Security/>}/>
        <Route path="software" element={<SoftWare/>}/>
        <Route path="quoteInquiry" element={<QuoteInquiry/>}/>
        <Route path="directions" element={<Directions/>}/>
      </Routes>
      <ScrollTop/>
      <Footer/>
    </>
  );
}

export default App;
