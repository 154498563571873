import React, { useEffect, useRef, useState } from 'react';
import '../SubLayout.css';
import emailjs from '@emailjs/browser';

export default function QuoteInquiry() {

  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  const [companyValue, setCompanyValue] = useState('');
  const [fnameValue, setFnameValue] = useState('');
  const [numberValue, setNumberValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [categoryValue, setCategoryValue] = useState('');
  const [questionValue, setQuestionValue] = useState('');

  // 견적문의 메일 전송 코드
  const inputRef = useRef();
  const emailInputRef = useRef();
  const form = useRef();
  const [emailError, setEmailError] = useState(true);
  const [phoneNumberError,setphoneNumberError] = useState(true);
  const [Content, setContent] = useState({
    compeny : '',
    user_name : '',
    user_phoneNumber : '',
    user_email : '',
    select_box : '',
    selectProduct : '',
    message : '',
  });

  const {compeny, user_name, user_phoneNumber,user_email, select_box ,message } = Content;


  useEffect(()=> {
    var phoneCheck = /^(01[016789]{1})[0-9]{4}[0-9]{4}$/;
      if(phoneCheck.test(Content.user_phoneNumber)){
        setphoneNumberError(true);
      } else {
    var phoneCheck = /^(070|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;
      if(phoneCheck.test(Content.user_phoneNumber)){
        setphoneNumberError(true);
      } else {
        setphoneNumberError(false);
      }
      }
  },[user_phoneNumber])

  useEffect(()=>{
    let email_Check = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
    if(!email_Check.test(Content.user_email)){
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  },[user_email])

  const onChangeHandler=(e)=>{
    const {name,value} = e.target;
    const nextValue = {
      ...Content,
      [name] : value,
    }
    setContent(nextValue);

    
  }

  const onReset = (e) => {
    setContent({
      compeny : '',
      user_name : '',
      user_phoneNumber : '',
      user_email : '',
      message : '',
      select_box : '',
    });
  };

  const sendEmail = (e) => {
    if(compeny == false || compeny == '' || compeny == ' ') {
      alert("회사/소속 칸을 작성해 주세요");
      e.preventDefault();
      return false;
    } else if (user_name == false || user_name == '' || user_name == ' ') {
      alert("성함을 작성해 주세요");
      e.preventDefault();
      return false;
    } else if (user_phoneNumber == false || user_phoneNumber == '' || user_phoneNumber == ' ' || phoneNumberError == false) {
      if(phoneNumberError == false) {
        alert("올바른 연락처가 아닙니다. ");
        inputRef.current.focus();
        e.preventDefault();
      } else {
        alert("연락처를 작성해 주세요");
        e.preventDefault();
      }
      return false;
    } else if (user_email == false || user_email == '' || user_email == ' ' ||  emailError == false ) {
      if(emailError == false) {
        alert ("올바른 이메일을 작성해 주세요 예시) sales@jnetsystems.com ");
        emailInputRef.current.focus();
        e.preventDefault();
      } else {
        alert("이메일을 작성해 주세요");
        e.preventDefault();
      }
      return false;
    } else if (select_box == false || select_box == ''  || select_box == ' ') {
      alert("문의분야를 선택해 주세요");
      e.preventDefault();
      return false;
    } else if (message == false || message == '' || message == ' ') {
      alert("문의내용을 작성해 주세요");
      e.preventDefault();
      return false;
    } else {
    e.preventDefault();

    emailjs.sendForm('service_fih0lqk','template_ydoa0av',form.current,'y9ZczIdPmt8TiSoAm')
    .then((result) =>{
      console.log(result.text);
      alert("견적문의 등록이 완료 되었습니다.");
      onReset();
    }, (error) => {
      console.log(error.text);
    })
    }
  }

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive); // isActive 상태를 토글
  };
  
  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit"><br></br>견적문의</h2> 
            <p className="sv-txt"> </p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li className="on">
                  <a href="/QuoteInquiry"><span>견적문의</span></a>
                </li>
                <li>
                  <a href="/Directions"><span>오시는길</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>고객문의</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li className="on">
              <a href="/QuoteInquiry"><span>견적문의</span></a>
            </li>
            <li>
              <a href="/Directions"><span>오시는길</span></a>
            </li>
          </ul>
        </div>
      </div>

      <section className="customer" data-aos="fade-right">
        <div className="sub-inner">
          <div className="title">
            <div>
              <h3 className="sc-tit">
                문의를 남겨주시면<br></br>
                신속히 처리해드리겠습니다
              </h3>
              <p className="required"><i>*표시는 필수 입력 항목입니다.</i></p>
            </div>
            <div className="info-wrap">
              <dl>
                <dt>주소</dt><dd>경기도 수원시 영통구 법조로 25, A동 2014호</dd>
              </dl>
              <dl>
                <dt>대표 번호</dt><dd>02 - 2294 - 0002</dd>
              </dl>
              <dl>
                <dt>대표 이메일</dt><dd>sales@jnetsystems.com</dd>
              </dl>
              <dl>
                <dt>팩스</dt><dd>02 - 2292 - 0002</dd>
              </dl>
            </div>
          </div>

          {/* -------------------------------- 입력 폼 ------------------------------ */}
          <div className="apply-form" data-aos="fade-up">
            <form ref={form} name="fr" onSubmit={sendEmail}>
              <div className="form-group">
                <div className="form-item col-10 col-5">
                  <div className="item-wrap">
                    <div className={`title ${compeny !== '' ? 'has-content' : ''}`}>
                      <label>회사/소속</label>
                    </div>
                    <div className="content">
                      <input
                        id="company"
                        name="compeny"
                        type="text"
                        className="input-default"
                        onChange={onChangeHandler}
                        value={compeny || ''}
                        required
                      >
                      </input>
                    </div>
                  </div>
                </div>
                <div className="form-item col-10 col-5">
                  <div className="item-wrap">
                    <div className={`title ${user_name ? 'has-content' : ''}`}>
                      <label>성함*</label>
                    </div>
                    <div className="content">
                      <input
                        id='fname'
                        name="user_name"
                        type="text"
                        className="input-default"
                        onChange={onChangeHandler}
                        value={user_name || ''}
                        required
                      >
                      </input>
                    </div>
                  </div>
                </div>
                <div className="form-item col-10 col-5">
                  <div className="item-wrap">
                    <div className={`title ${user_phoneNumber ? 'has-content' : ''}`}>
                      <label>연락처*</label>
                    </div>
                    <div className="content">
                      <input
                        id='number'
                        name="user_phoneNumber"
                        type="text"
                        className="input-default"
                        onChange={onChangeHandler}
                        value={user_phoneNumber || ''}
                        ref={inputRef}
                        required 
                      >
                      </input>
                    </div>
                  </div>
                </div>
                <div className="form-item col-10 col-5">
                  <div className="item-wrap">
                    <div className={`title ${user_email ? 'has-content' : ''}`}>
                      <label>이메일*</label>
                    </div>
                    <div className="content">
                      <input
                        id='email'
                        name="user_email"
                        type="email"
                        className="input-default"
                        onChange={onChangeHandler}
                        value={user_email || ''}
                        ref={emailInputRef}
                        required
                      >
                      </input>
                    </div>
                  </div>
                </div>
                <div className="form-item col-10">
                  <div className="item-wrap">
                    <div className={`title ${select_box ? 'has-content' : ''}`}>
                      <label>문의 분야*</label>
                    </div>
                    <div className="content">
                      <select
                        id="category"
                        className="input-default"
                        name="select_box" 
                        onChange={onChangeHandler}
                        value={select_box || ''}
                        required
                      >
                        <option name="product" value="" ></option>
                        <option nmae="ipt" value="ipt">IPT</option>
                        <option name="Network" value="network">NETWORK</option>
                        <option name='WLan' value="wlan">WLAN</option>
                        <option name="보안" value="security">보안</option>
                        <option name="개발" value="r&d">개발</option>
                      </select>
                      <input required type='text'name="selectProduct" style={{display:"none"}} onChange={onChangeHandler} value={select_box || ""} ></input>
                    </div>
                  </div>
                </div>
                <div className="form-item col-10">
                  <div className="item-wrap">
                    <div className={`title ${message ? 'has-content' : ''}`}>
                      <label>문의내용*</label>
                    </div>
                    <div className="content">
                      <textarea
                        id="question"
                        className="input-default"
                        name="message"
                        value={message || ''}
                        onChange={onChangeHandler}
                        required
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>

              <label className={`check-box ${isActive ? 'active' : 'active'}`} onClick={handleClick}>
                <input type="checkbox" id="agree" className="input-check vaild-input"></input>
                <span></span>
                <a href="#" onClick={e => { e.preventDefault(); }} className="check-label">
                  개인정보수집이용 동의
                </a>
              </label>
              <div className="btn-group">
              { <input type="submit" value="Send" />}
            </div>
            </form>

            
          </div>
        </div>
      </section>
    </div>
  )
}
