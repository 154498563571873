import cb001 from './cb_001.svg';
import cb002 from './cb_002.svg';
import cb003 from './cb_003.svg';
import cb004 from './cb_004.svg';
import cb005 from './cb_005.svg';
import cb006 from './cb_006.svg';
import cb007 from './cb_007.svg';
import cb008 from './cb_008.svg';
import cbCube from './cb_cube.png';
import displayBg from './display_bg.jpg';
import displayLeftBg from './display_left_bg.svg';
import displayRightBg from './display_right_bg.svg';
import gsMark from './gs_mark.png';
import jnms from './jnms.svg';
import jnmsEmblem from './jnms_emblem.svg';
import wisightMockup from './wisight_mockup.png';
import wisight from './wisight.svg';
import diAp from './dicon_ap.svg';
import diBuild from './dicon_build.svg';
import diIpp from './dicon_ipphone.svg';
import diMobile from './dicon_mobile.svg';
import diServer from './dicon_server.svg';
import diSwitchpoe from './dicon_switchpoe.svg';
import site_image_001 from './site_image_001.png';
import site_image_002 from './site_image_002.png';
import site_image_003 from './site_image_003.png';
import site_image_004 from './site_image_004.png';
import site_image_005 from './site_image_005.png';
import site_image_006 from './site_image_006.png';
import site_image_007 from './site_image_007.png';
import site_image_008 from './site_image_008.png';
import site_image_009 from './site_image_009.png';
import site_image_010 from './site_image_010.png';
import site_image_011 from './site_image_011.png';
import site_image_012 from './site_image_012.png';
import site_image_013 from './site_image_013.png';
import site_image_014 from './site_image_014.png';
import site_image_015 from './site_image_015.png';
import site_image_016 from './site_image_016.png';
import site_image_017 from './site_image_017.png';
import site_image_018 from './site_image_018.png';
import demo_map01 from './demo_map01.png';
import demo_map02 from './demo_map02.png';
import demo_map03 from './demo_map03.png';
import demo_map04 from './demo_map04.png';


const cbImages = {
  cb001,
  cb002,
  cb003,
	cb004,
  cb005,
  cb006,
  cb007,
  cb008,
  cbCube,
	displayBg,
  displayLeftBg,
  displayRightBg,
	gsMark,
	jnms,
  jnmsEmblem,
	wisightMockup,
	wisight,
  diAp,
  diBuild,
  diIpp,
  diMobile,
  diServer,
  diSwitchpoe,
  site_image_001,
  site_image_002,
  site_image_003,
  site_image_004,
  site_image_005,
  site_image_006,
  site_image_007,
  site_image_008,
  site_image_009,
  site_image_010,
  site_image_011,
  site_image_012,
  site_image_013,
  site_image_014,
  site_image_015,
  site_image_016,
  site_image_017,
  site_image_018,
  demo_map01,
  demo_map02,
  demo_map03,
  demo_map04,
};

export default cbImages;