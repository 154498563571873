import React, { useEffect, useRef, useState } from 'react';
import '../SubLayout.css';
import benefit1 from './images/benefit1.svg';
import benefit2 from './images/benefit2.svg';
import benefit3 from './images/benefit3.svg';
import benefit4 from './images/benefit4.svg';
import benefit5 from './images/benefit5.svg';
import benefit6 from './images/benefit6.svg';

import { Swiper, SwiperSlide } from '../../../node_modules/swiper/swiper-react';
import { Parallax, FreeMode, Pagination } from '../../../node_modules/swiper/modules';

export default function Recruit() {

  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }


  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit">CAREERS<br></br>& BENEFITS</h2>
            <p className="sv-txt">하드웨어와 소프트웨어의 안정적인 공급을 위한 토탈 IT 비즈니스 솔루션을 제공하는 전문기업</p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li>
                  <a href="/CompanyIntro"><span>기업정보</span></a>
                </li>
                <li className="on">
                  <a href="/Recruit"><span>채용정보</span></a>
                </li>
                <li>
                  <a href="/jsoftlab"><span>제이소프트랩</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>회사소개</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li><a href="/CompanyIntro"><span>기업정보</span></a></li>
            <li className="on"><a href="/Recruit"><span>채용정보</span></a></li>
            <li><a href="/jsoftlab"><span>제이소프트랩</span></a></li>
          </ul>
        </div>
      </div>

      <section className="talent">
        <div className="sub-inner">
          <div className="title">
            <h3 className="sc-tit">(주)제이넷시스템즈가 바라는 인재상</h3>
          </div>

          <div className="content">
            <ul className="talent-list">
              <li data-aos="fade-up">
                <div>
                  <p><strong>책임감 있는 인재</strong></p>
                  <p>일에 대한 사명감을 가지고 <br></br>조직 목표달성을 위해 최선을 다하는 인재</p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="500">
                <div>
                  <p><strong>신뢰할 수 있는 인재</strong></p>
                  <p>올바른 가치관으로 구성원과 <br></br>신의와 믿음을 공유할 수 있는 인재</p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <p><strong>전문적인 인재</strong></p>
                  <p>고도의 전문지식과 경험으로 새로운 가치를 <br></br>창조할 수 있는 인재</p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="1500">
                <div>
                  <p><strong>소통가능한 인재</strong></p>
                  <p>상호이해와 존중을 바탕으로 <br></br>서로 협력하여 높은 성과를 창출하는 인재</p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="2000">
                <div>
                  <p><strong>도전적인 인재</strong></p>
                  <p>독창적 사고, 창의력을 바탕으로 <br></br>새로운 기회를 만들어 내는 인재</p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="2500">
                <div></div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="step">
        <div className="sub-inner">
          <div className="title" data-aos="fade-right">
            <h3 className="sc-tit">채용절차</h3>
            <p className="txt">※ 세부 절차는 변동이 있을 수 있으니 자세한 내용은 각 채용공고를 통해 확인하여 주시기 바랍니다.</p>
          </div>

          <div className="content">
            <Swiper
              modules={Pagination}
              pagination={{
                clickable: true
              }}
              className="step-slide"
              breakpoints={{
                1500: {
                  slidesPerView: 5,
                  spaceBetween: 10
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                767: {
                  slidesPerView: 1,
                  spaceBetween: 20
                }
              }}
              data-aos="fade-right"
            >
              <SwiperSlide>
                <div className="step-item">
                  <div className="after"></div>
                  <div className="line"></div>
                  <span className="top">STEP 01</span>
                  <p>입사지원</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="step-item">
                  <div className="after"></div>
                  <div className="line"></div>
                  <span className="top">STEP 02</span>
                  <p>서류심사</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="step-item">
                  <div className="after"></div>
                  <div className="line"></div>
                  <span className="top">STEP 03</span>
                  <p>해당업무면접</p>
                  <em className="bottom">*지원부터 팀장 직무 위주</em>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="step-item">
                  <div className="after"></div>
                  <div className="line"></div>
                  <span className="top">STEP 04</span>
                  <p>임원면접</p>
                  <em className="bottom">*인성위주</em>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="step-item">
                  <div className="after"></div>
                  <div className="line"></div>
                  <span className="top">STEP 05</span>
                  <p>채용확정</p>
                </div>
              </SwiperSlide>
            </Swiper>

            <div className="btn-bl-bk">
              <a href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/jnet2014" target="_blank" rel="noopener noreferrer">채용공고 보러가기</a>
            </div>
          </div>
        </div>
      </section>

      <section className="benefits">
        <div className="sub-inner">
          <div className="title" data-aos="fade-right" data-aos-duration="2000">
            <h3 className="sc-tit">복리후생</h3>
          </div>
          <div className="content">
            <ul>
              <li data-aos="fade-up" data-aos-duration="500">
                <span><img src={benefit1}></img></span>
                <p>중・석식 제공</p>
              </li>
              <li data-aos="fade-up" data-aos-duration="1000">
                <span><img src={benefit2}></img></span>
                <p>경조사 지원</p>
              </li>
              <li data-aos="fade-up" data-aos-duration="1500">
                <span><img src={benefit3}></img></span>
                <p>명절 선물</p>
              </li>
              <li data-aos="fade-up" data-aos-duration="2000">
                <span><img src={benefit4}></img></span>
                <p>외부 교육 제공</p>
              </li>
              <li data-aos="fade-up" data-aos-duration="2500">
                <span><img src={benefit5}></img></span>
                <p>자유로운 연차사용</p>
              </li>
              <li data-aos="fade-up" data-aos-duration="3000">
                <span><img src={benefit6}></img></span>
                <p>생일 케이크 선물</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}