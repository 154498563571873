import React, { useEffect, useState } from 'react';
import '../SubLayout.css';

import svc1 from '../business/images/ipt_svc1.svg';
import svc2 from '../business/images/ipt_svc2.svg';
import svc3 from '../business/images/ipt_svc3.svg';
import svc4 from '../business/images/ipt_svc4.svg';
import svc5 from '../business/images/ipt_svc5.svg';


export default function IptConsulting() {
  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  const [activeCol, setActiveCol] = useState(0);

  const handleClick = (index) => {
    setActiveCol(index);
  };

  const handleMouseOver = (index) => {
    setActiveCol(index);
  };

  const handleMouseOut = () => {
    // 마우스가 나간 후에 activeCol을 null로 설정하지 않음
  };

  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit">IPT SYSTEM<br></br>CONSULTING</h2>
            <p className="sv-txt">안정적인 기술과 우수한 신뢰성으로 고객만족을 위한 솔루션을 혁신하고 제공합니다.</p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li className="on">
                  <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
                </li>
                <li>
                  <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
                </li>
                <li>
                  <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
                </li>
                <li>
                  <a href="/Maintenance"><span>통합 유지보수</span></a>
                </li>
                <li>
                  <a href="/Distributor"><span>총판사업</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>사업분야</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li className="on">
              <a href="/IptConsulting"><span>IPT 시스템 컨설팅</span></a>
            </li>
            <li>
              <a href="/NetworkBuilding"><span>NETWORK 구축 컨설팅</span></a>
            </li>
            <li>
              <a href="/WlanBuilding"><span>WLAN 구축 컨설팅</span></a>
            </li>
            <li>
              <a href="/Maintenance"><span>통합 유지보수</span></a>
            </li>
            <li>
              <a href="/Distributor"><span>총판사업</span></a>
            </li>
          </ul>
        </div>
      </div>

      <section className="governing">
        <div className="sub-inner">
          <div className="title">
            <h3 className="tit" data-aos="fade-up">
              IPT시스템 컨설팅
            </h3>
            <p className="txt" data-aos="fade-up">
              제이넷시스템즈는 최신 통신 기술을 활용하여 고객에게 <br></br>효율적이고 안정적인 서비스를 구축하고 전문적인 지원을 제공합니다
            </p>
          </div>
        </div>
      </section>

      <section className="business-info">
        <div className="sub-inner">
          <ul className="info-list">
            <li>
              <div className="bg" data-aos="fade-right"><span></span></div>
              <div className="con" data-aos="fade-up">
                <p className="num">01</p>
                <p className="tit">안정성</p>
                <p className="txt">
                  시스템 다중화 검증된 제품 도입<br></br>
                  전문인력 활용 등 철저한 구축 전략 수립
                </p>
                <ul>
                  <li>- 시스템 다중화</li>
                  <li>- 검증된 제품 도입</li>
                  <li>- IPT 보안성 검토 기준 준수</li>
                </ul>
              </div>
            </li>

            <li>
              <div className="bg" data-aos="fade-left"><span></span></div>
              <div className="con" data-aos="fade-up">
                <p className="num">02</p>
                <p className="tit">효율성</p>
                <p className="txt">
                  사용자 중심의 편의성, 표준 기술 적용
                </p>
                <ul>
                  <li>- 중앙 호 집중 설계</li>
                  <li>- 통합 IPT 구축</li>
                  <li>- 중단 없는 서비스</li>
                </ul>
              </div>
            </li>

            <li>
              <div className="bg" data-aos="fade-right"><span></span></div>
              <div className="con" data-aos="fade-up">
                <p className="num">03</p>
                <p className="tit">확장성</p>
                <p className="txt">
                  고 가용성의 미래 지향적 시스템 공급 및<br></br>
                  확장성을 고려한 기반 설계
                </p>
                <ul>
                  <li>- IP-PBX 수용능력 60,000회선급 제안</li>
                  <li>- IP기반 부가장비 연동성 확보</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="process">
        <div className="sub-inner">
          <div className="title">
            <h3 className="mc-tit" data-aos="fade-up" data-aos-duration="800">프로세스</h3>
          </div>
          <div className="contents-box" data-aos="fade-up" data-aos-duration="800">
            <div className="proc-slide">
              <div className="proc-list">
                <div className="grid">
                  <div
                    className={`col ${activeCol === 0 ? 'active' : ''}`}
                    onClick={() => handleClick(0)}
                    onMouseOver={() => handleMouseOver(0)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>01</span>
                          <p>정확한 현황 분석</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>교환기 및 서비스 현황 파악</p></li>
                            <li><p>본청/외청의 전화기 수량 파악</p></li>
                            <li><p>각 부서별 인원 파악</p></li>
                            <li><p>각 부서의 업무 특성 파악</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 1 ? 'active' : ''}`}
                    onClick={() => handleClick(1)}
                    onMouseOver={() => handleMouseOver(1)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>02</span>
                          <p>안정성 및 확장성 확보</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>IP교환기, 미디어게이트웨이, 아날로그게이트웨이, IP전화기를 동일 벤더 장비로 제공하여 호환성/안정성 확보</p></li>
                            <li><p>SIP 표준 프로토콜을 통한 확장성 확보</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 2 ? 'active' : ''}`} 
                    onClick={() => handleClick(2)}
                    onMouseOver={() => handleMouseOver(2)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>03</span>
                          <p>신기술 적용을 통한 장애 최소화</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>IP교환기, 미디어게이트웨이 중요 모듈 이중화 보안프로토콜(TLS,sRTP), QoS</p></li>
                            <li><p>Geo-Redundancy 등 신기술을 적용하여 보안성 확보 및 장애 최소화</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ${activeCol === 3 ? 'active' : ''}`} 
                    onClick={() => handleClick(3)}
                    onMouseOver={() => handleMouseOver(3)}
                    onMouseOut={handleMouseOut}
                  >
                    <div className="proc-item">
                      <div className="item-inner">
                        <div className="tit">
                          <span>04</span>
                          <p>다양한 부가장비 연동 방안 마련</p>
                        </div>
                        <div className="area">
                          <ul>
                            <li><p>표준 프로토콜을 이용한 ARS/컬러링/UC 등 다양한 부가서비스 연동 기반 확보</p></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagi">
                <span className={`bullet ${activeCol === 0 ? 'active' : ''}`} onClick={() => handleClick(0)} />
                <span className={`bullet ${activeCol === 1 ? 'active' : ''}`} onClick={() => handleClick(1)} />
                <span className={`bullet ${activeCol === 2 ? 'active' : ''}`} onClick={() => handleClick(2)} />
                <span className={`bullet ${activeCol === 3 ? 'active' : ''}`} onClick={() => handleClick(3)} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service">
        <div className="sub-inner">
          <div className="title" data-aos="fade-right">
            <h3 className="sc-tit">주요 서비스</h3>
          </div>
          <div className="content" data-aos="fade-up">
            <ul className="svc-list">
              <li className="item">
                <div className="icon"><img src={svc1}></img></div>
                <div>
                  <p>IP-PBX 시스템</p>
                  <ul>
                    <li>- Call Server</li>
                    <li>- Media Gateway</li>
                    <li>- Virtualization</li>
                  </ul>
                </div>
              </li>
              <li className="item">
                <div className="icon"><img src={svc2}></img></div>
                <div>
                  <p>IP Phone</p>
                  <ul>
                    <li>- IP Phone</li>
                    <li>- Soft-Phone</li>
                    <li>- Browser Phone</li>
                    <li>- Digital Phone</li>
                  </ul>
                </div>
              </li>
              <li className="item">
                <div className="icon"><img src={svc3}></img></div>
                <div>
                  <p>Application</p>
                  <ul>
                    <li>- IPCC</li>
                    <li>- mVolP(FMC)</li>
                    <li>- Web RTC</li>
                    <li>- UC</li>
                    <li>- NMS</li>
                  </ul>
                </div>
              </li>
              <li className="item">
                <div className="icon"><img src={svc4}></img></div>
                <div>
                  <p>부가솔루션</p>
                  <ul>
                    <li>- IVR/ARS</li>
                    <li>- 컬러링/녹취</li>
                    <li>- Web FAX</li>
                    <li>- 과금장비</li>
                    <li>- XML</li>
                  </ul>
                </div>
              </li>
              <li className="item">
                <div className="icon"><img src={svc5}></img></div>
                <div>
                  <p>3rd Party</p>
                  <ul>
                    <li>- VolP 방화벽</li>
                    <li>- 스마트 알림 서비스</li>
                    <li>- 자율 좌석제</li>
                    <li>- 화상회의</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="inquiry_banner">
        <div className="sub-inner">
          <h3 className="sc-tit" data-aos="fade-up">궁금한 사항을 간편하게 문의해보세요</h3>
          <p className="sc-txt" data-aos="fade-up">제이넷시스템즈의 전문가가 궁금한 점을 해결해 드립니다</p>
          <a href="/QuoteInquiry" data-aos="fade-up">문의하기</a>
        </div>
      </section>
    </div>
  )
}
