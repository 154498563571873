import React, { useRef, useState, useEffect, lazy, Suspense } from 'react';
import '../SubLayout.css';
import '../../css/style.css';

// const FireWall = lazy(() => import('../../components/product/security/fireWall'));
// const Wips = lazy(() => import('../../components/product/security/wips'));
// const Integrated = lazy(() => import('../../components/product/security/integrated'));


export default function SoftWare() {
  
  const [isTopFixed, setTopFixed] = useState(false);

  useEffect(() => {
    const svTitle = document.querySelector('.sv-title');
    const lnb = document.querySelector('.lnb.top-fixed');

    const handleScroll = () => {
      const svTitleHeight = svTitle.getBoundingClientRect().height;
      const scrollY = window.scrollY;

      if (scrollY >= svTitleHeight) {
        setTopFixed(true);
      } else {
        setTopFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  // const [activeComponent, setActiveComponent] = useState(FireWall);
  // const [activeButton, setActiveButton] = useState('FireWall');

  // const loadComponent = (componentName) => {
  //   if (componentName === 'FireWall') {
  //     setActiveComponent(FireWall);
  //   } else if (componentName === 'Wips') {
  //     setActiveComponent(Wips);
  //   } else if (componentName === 'Integrated') {
  //     setActiveComponent(Integrated);
  //   }
  //   setActiveButton(componentName);
  // };

  return (
    <div className="sub container">
      <div className="visual">
        <div className="sv-title">
          <div>
            <h2 className="sv-tit"><br></br>R&D</h2>
            <p className="sv-txt">효율적인 솔루션을 위한 제품을 제공합니다.</p>
          </div>
        </div>
        <div className={`lnb ${isTopFixed ? ' top-fixed' : ''}`}>
          <div className="lnb-inner">
            <div className="area">
              <ul className="sub-menu">
                <li>
                  <a href="/Ipt"><span>IPT</span></a>
                </li>
                <li>
                  <a href="/Network"><span>NETWORK</span></a>
                </li>
                <li>
                  <a href="/Wlan"><span>WLAN</span></a>
                </li>
                <li>
                  <a href="/Security"><span>SECURITY</span></a>
                </li>
                <li className="on">
                  <a href="/Software"><span>R&D</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <button className="dropdown-button" onClick={toggleDropdown}>
            <span>제품정보</span><span></span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a href="/Ipt"><span>IPT</span></a>
            </li>
            <li>
              <a href="/Network"><span>NETWORK</span></a>
            </li>
            <li>
              <a href="/Wlan"><span>WLAN</span></a>
            </li>
            <li>
              <a href="/Security"><span>SECURITY</span></a>
            </li>
            <li className="on">
              <a href="/Software"><span>R&D</span></a>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="sub-tab">
        <div className="sub-inner">
          <button className={activeButton === 'FireWall' ? 'on' : ''} onClick={() => loadComponent('FireWall')}>방화벽</button>
          <button className={activeButton === 'Wips' ? 'on' : ''} onClick={() => loadComponent('Wips')}>WIPS</button>
          <button className={activeButton === 'Integrated' ? 'on' : ''} onClick={() => loadComponent('Integrated')}>통합로그</button>
        </div>
      </div> */}

      <div className="contents">
        {/* <Suspense 
          fallback={
            <div className="loader">
              <div className="loader-box">
                <div></div>
              </div>
            </div>
          }
        >
          {activeComponent && React.createElement(activeComponent)}
        </Suspense> */}
      </div>
    </div>
  )
}